import styled from 'styled-components'
import PharmaParkBg from '@/assets/images/pharmaparkBg.webp'
import PTUBg from '@/assets/images/ptuBg.webp'
import { useTranslation } from 'react-i18next'

type DocumentsWidgetProps = {
  bgImage: string
  children: React.ReactNode
}

const CardWidgetComponent = ({ children, bgImage }: DocumentsWidgetProps) => {
  return (
    <CardWidget className="flex-1" $bgImage={bgImage}>
      {children}
    </CardWidget>
  )
}

export const DocumentsWidget = () => {
  const { t } = useTranslation()
  return (
    <section className="grid w-full grid-cols-1 gap-6 px-4 lg:grid-cols-2 lg:px-0">
      <CardWidgetComponent bgImage={PharmaParkBg}>
        <div className="flex h-full w-3/4 flex-col justify-around gap-6">
          <p className="typography-h3 font-bold">{t('homePage.pictures.pharmPark')}</p>
          <span>{t('homePage.pictures.pharmParkText')}</span>
        </div>
      </CardWidgetComponent>
      <CardWidgetComponent bgImage={PTUBg}>
        <div className="flex h-full w-3/4 flex-col justify-around gap-6">
          <p className="typography-h3 font-bold uppercase">{t('homePage.pictures.FTU')}</p>
          <span>{t('homePage.pictures.FTUText')}</span>
        </div>
      </CardWidgetComponent>

      {/* <CardWidgetComponent bgImage={AppBg}>
        <div className="flex h-full w-1/2 flex-col justify-between gap-8">
          <div>
            <p className="typography-h3">Мобильное приложение</p>
            <h1 className="typography-h2 font-black">UZPHARM</h1>
          </div>
          <div className="flex gap-8">
            <Link to="/">
              <img src={AppStoreBtn} alt="App Store" />
            </Link>
            <Link to="/">
              <img src={PlayStoreBtn} alt="Google Play" />
            </Link>
          </div>
        </div>
      </CardWidgetComponent> */}
    </section>
  )
}

const CardWidget = styled.div<{ $bgImage: string }>`
  background-image: url(${({ $bgImage }) => $bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 15px;
  padding: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  color: white;
`
