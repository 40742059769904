import { Pagination, Select, Table, TablePaginationConfig, TableProps } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { PaginationItem } from './paginationItem'

type CustomTableProps<T> = {
  dataSource: T[]
  headerTitle?: string
  pageSizeOptions?: {
    value: number
    label: string
  }[]
  columns: TableProps['columns']
  position?: TablePaginationConfig['position']
  expandedRowComponent?: React.ReactNode
  classes?: string
  page: number
  pageSize: number
  setPageSize: (size: number) => void
  setPage: (page: number) => void
  totalPages: number
  currentItems?: number
  rowClassName?: 'success' | 'warning' | 'error'
  defaultExpandAllRows?: boolean
  rowExpandable?: (record: T) => boolean
  expandRowByClick?: boolean
}

interface TableRecord {
  statusKey?: string
  key: string
  desc?: string
}

const TableExpandable = <T extends TableRecord>({
  dataSource,
  columns,
  position,
  expandedRowComponent,
  classes,
  page,
  pageSize,
  totalPages,
  setPageSize,
  setPage,
  headerTitle,
  defaultExpandAllRows,
  rowExpandable,
  expandRowByClick,
  ...props
}: CustomTableProps<T> & TableProps) => {
  const { t } = useTranslation()
  const [expandedRows, setExpandedRows] = useState<string[]>([])

  const toggleExpand = (key: string) => {
    setExpandedRows((prev) => (prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key]))
  }

  return (
    <div className="relative bg-white py-5 pt-20">
      <div className="mb-6 flex justify-between px-6">
        {headerTitle && <h1 className="text-[32px] font-bold">{headerTitle}</h1>}
        <div className={`flex flex-1 justify-end gap-10 ${!headerTitle && 'justify-between'}`}>
          <div className="flex items-center gap-4">
            <Select
              className="custom-select"
              defaultValue={'byAlphabet'}
              style={{ width: 140 }}
              options={[{ value: 'byAlphabet', label: 'По алфавиту' }]}
            />
            <span className="typography-body font-semibold">{t('resultsPerPage')}</span>
            <Select
              className="custom-select"
              defaultValue={10}
              value={pageSize}
              style={{ width: '60px' }}
              options={[
                { value: 2, label: 2 },
                { value: 5, label: 5 },
                { value: 10, label: 10 },
                { value: 12, label: 12 },
                { value: 15, label: 15 },
              ]}
              onChange={(value: number) => {
                setPageSize(value)
              }}
            />
          </div>
          <CostumPagination
            current={page + 1}
            pageSize={pageSize}
            itemRender={PaginationItem}
            total={totalPages}
            showLessItems={true}
            showQuickJumper={false}
            showSizeChanger={false}
            hideOnSinglePage={true}
            onChange={setPage}
          />
        </div>
      </div>
      <CostumeTable
        bordered={false}
        className={`${classes}`}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowClassName={(record: T) =>
          `cursor-pointer ${expandedRows?.includes(record.key) ? 'expanded-row' : ''} ${record.statusKey}`
        }
        expandable={{
          showExpandColumn: false,
          expandRowByClick: !defaultExpandAllRows || expandRowByClick,
          defaultExpandAllRows,
          expandedRowRender: () => expandedRowComponent,
          rowExpandable: rowExpandable || (() => false),
          onExpand: (_, record: T) => {
            if (toggleExpand) toggleExpand(record.key)
          },
        }}
        {...props}
      />
      <div className="mt-6 flex justify-end">
        <CostumPagination
          current={page + 1}
          pageSize={pageSize}
          itemRender={PaginationItem}
          total={totalPages}
          showLessItems={true}
          showQuickJumper={false}
          showSizeChanger={false}
          hideOnSinglePage={true}
          onChange={setPage}
        />
      </div>
    </div>
  )
}

export default TableExpandable

const CostumPagination = styled(Pagination)`
  .ant-pagination-item-active {
    background-color: #0d6efd;
    color: #ffffff;
    border-radius: 50%;

    .costume-pagination-item-own {
      background-color: #0d6efd;
      color: #ffffff;
    }
  }

  .ant-pagination-item {
    &:hover {
      background-color: transparent !important;
    }
  }
`

const CostumeTable = styled(Table)`
  .ant-table-thead {
    border-radius: 0 !important;
    .ant-table-cell::before,
    .ant-table-cell::after {
      display: none;
    }
    .ant-table-cell:first-child {
      background-color: #3874ff;
      color: #fff;
      border-start-start-radius: 0 !important;
    }
    .ant-table-cell:last-child {
      border-start-end-radius: 0 !important;
    }
    .ant-table-cell {
      padding-top: 36px;
      padding-bottom: 58px;
      background-color: #f2f5fd;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell:first-child {
        background-color: #f2f5fd;
      }
    }
  }
`
