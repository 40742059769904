import Pills from '@/assets/icons/pills.svg'
import Syringe from '@/assets/icons/syringe.svg'
import Cardiogram from '@/assets/icons/cardiogram.svg'
import Drugs from '@/assets/icons/drugs.svg'
import FirstAid from '@/assets/icons/firstAid.svg'
import Catalog from '@/assets/icons/catalog.svg'
import CatalogBioactive from '@/assets/icons/catalogBioactive.svg'
import Cosmetics from '@/assets/icons/cosmetics.svg'
import RegSubstance from '@/assets/icons/regSubstance.svg'
import { DRUGS, MANUFACTURERS } from '@/routes/paths'

interface ServiceData {
  title: string
  icon: string
  count: number
  bgColor: string
  className?: string
  path: string
  value: string
}

export const servicesData: ServiceData[] = [
  {
    title: 'Лекарственные средства',
    icon: Pills,
    count: 519,
    bgColor: '#6BA2F3',
    className: 'lg:row-span-2',
    path: DRUGS,
    value: 'drugs',
  },
  {
    title: 'Изделия медицинского назначения',
    icon: Syringe,
    count: 10766,
    bgColor: '#67B3F1',
    path: '/',
    value: 'medicalDevices',
  },
  {
    title: 'Медицинская техника',
    icon: Cardiogram,
    count: 10766,
    bgColor: '#E0A976',
    path: '/',
    value: 'medicalEquipment',
  },
  {
    title: 'Био активные добавки',
    icon: CatalogBioactive,
    count: 10766,
    bgColor: '#8297CC',
    path: '/',
    value: 'bioactiveAdditives',
  },
  {
    title: 'Косметика',
    icon: Cosmetics,
    count: 10766,
    bgColor: '#E58E8E',
    className: 'col-span-2',
    path: '/',
    value: 'cosmetics',
  },
  {
    title: 'Субстанции',
    icon: RegSubstance,
    count: 15250,
    bgColor: '#B37DCD',
    className: 'row-span-2',
    path: '/',
    value: 'substances',
  },
  { title: 'Производители', icon: Drugs, count: 231, bgColor: '#80CB6D', path: MANUFACTURERS, value: 'manufacturers' },
  { title: 'Дистрибьюторы', icon: FirstAid, count: 10766, bgColor: '#68CFBD', path: '/', value: 'distributors' },
  { title: 'Аптеки', icon: Catalog, count: 10766, bgColor: '#C2C975', path: '/', value: 'pharmacies' },
]

export const servicesTitle = [
  'Лекарственные средства',
  'Изделия медицинского назначения',
  'Медицинская техника',
  'Производители',
  'Дистрибьюторы',
  'Аптеки',
  'Био активные добавки',
  'Косметика',
  'Субстанции',
  'Вет препараты',
]

export const servicesIcons = [
  Pills,
  Syringe,
  Cardiogram,
  Drugs,
  FirstAid,
  Catalog,
  CatalogBioactive,
  Cosmetics,
  RegSubstance,
  Drugs,
]

export const servicesTitleAndValue = [
  {
    title: 'Лекарственные средства',
    value: 'drugs',
  },
  {
    title: 'Изделия медицинского назначения',
    value: 'medicalDevices',
  },
  {
    title: 'Медицинская техника',
    value: 'medicalEquipment',
  },
  {
    title: 'Производители',
    value: 'manufacturers',
  },
  {
    title: 'Дистрибьюторы',
    value: 'distributors',
  },
  {
    title: 'Аптеки',
    value: 'pharmacies',
  },
  {
    title: 'Био активные добавки',
    value: 'bioactiveAdditives',
  },
  {
    title: 'Косметика',
    value: 'cosmetics',
  },
  {
    title: 'Субстанции',
    value: 'substances',
  },
  {
    title: 'Вет препараты',
    value: 'vetDrugs',
  },
]
