import { CustomTable } from '@/components/ui/table'
import { Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import EditIcon from '@/assets/icons/ic_edit.svg'
import DeleteIcon from '@/assets/icons/ic_delete.svg'
import ContentTitle from '@/components/dashboard/ContentTitle'
import { useNavigate } from 'react-router-dom'
import paths from '@/routes/paths'
import styled from 'styled-components'
import { useState } from 'react'

const data = [
  {
    applicationNumber: '0000001',
    inspection: 'GPP',
    inspectionObject: {
      name: 'PREMIER electronics: Ташкент, Tashkent, Узбекистан',
      address: `Mirobod ko'chasi 25, 100031, Тоshkent, Узбекистан`,
    },
    creationDate: '2021-09-23',
    status: {
      title: 'Отправлено',
      statusKey: 'primary',
    },
  },
  {
    applicationNumber: '0000002',
    inspection: 'GPP',
    inspectionObject: {
      name: 'PREMIER electronics: Ташкент, Tashkent, Узбекистан',
      address: `Mirobod ko'chasi 25, 100031, Тоshkent, Узбекистан`,
    },
    creationDate: '2021-09-23',
    status: {
      title: 'Черновик',
      statusKey: 'default',
    },
    desc: 'Проверка документов',
  },
  {
    applicationNumber: '0000003',
    inspection: 'GPP',
    inspectionObject: {
      name: 'PREMIER electronics: Ташкент, Tashkent, Узбекистан',
      address: `Mirobod ko'chasi 25, 100031, Тоshkent, Узбекистан`,
    },
    creationDate: '2021-09-23',
    status: {
      title: 'Отклонена',
      statusKey: 'error',
    },
    statusKey: 'error',
  },
  {
    applicationNumber: '0000004',
    inspection: 'GPP',
    inspectionObject: {
      name: 'PREMIER electronics: Ташкент, Tashkent, Узбекистан',
      address: `Mirobod ko'chasi 25, 100031, Тоshkent, Узбекистан`,
    },
    creationDate: '2021-09-23',
    status: {
      title: 'Завершенно',
      statusKey: 'success',
    },
    statusKey: 'success',
  },
]

const statuses = {
  all: 'all',
  sented: 'sented',
  inProcess: 'inProcess',
  completed: 'completed',
  rejected: 'rejected',
}

const GXPApplications = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activeStatus, setActiveStatus] = useState(statuses.all)

  const statusesBtnData = [
    {
      statusKey: 'all',
      title: t('all'),
      count: 118,
      countBg: '#F97316',
    },
    {
      statusKey: 'sented',
      title: t('sent'),
      count: 118,
      countBg: '#1D51C6',
    },
    {
      statusKey: 'inProcess',
      title: t('inProcess'),
      count: 118,
      countBg: '#9038FF',
    },
    {
      statusKey: 'completed',
      title: t('completed'),
      count: 118,
      countBg: '#58B841',
    },
    {
      statusKey: 'rejected',
      title: t('rejected'),
      count: 118,
      countBg: '#F24E36',
    },
  ]

  const handleCreateNewApplication = () => {
    navigate(paths.gxpPaths.ADMIN_PANEL_GXP_REQUESTS_CREATE)
  }

  const StatusComponent = ({ title, statusKey }: { title: string; statusKey: string }) => {
    const classes: { [statusKey: string]: string } = {
      primary: 'bg-[#1D51C6]',
      default: 'bg-[#DCDCDC] !text-[#000]',
      error: 'bg-[#F24E37]',
      success: 'bg-[#58B841]',
    }
    return (
      <div
        className={`flex w-fit min-w-28 items-center justify-center rounded-[50px] px-3 pb-1 pt-2 ${classes[statusKey]}`}
      >
        <span className="typography-small font-helvetica font-semibold uppercase text-white">{title}</span>
      </div>
    )
  }

  const getColumns = [
    {
      title: 'Номер заявки',
      dataIndex: 'applicationNumber',
      key: 'applicationNumber',
      width: 180,
      render: (text: string) => <span className="font-inter font-semibold">{text}</span>,
    },
    {
      title: 'Инспекция',
      dataIndex: 'inspection',
      key: 'inspection',
      width: 100,
      render: (text: string) => (
        <div className="flex items-center gap-1">
          <span className="font-inter font-semibold">{text}</span>
          <Tooltip title="GPP - Государственная фармакопея Республики Узбекистан">
            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.5208 6.90417 10.7125 6.7125C10.9042 6.52083 11 6.28333 11 6C11 5.71667 10.9042 5.47917 10.7125 5.2875C10.5208 5.09583 10.2833 5 10 5C9.71667 5 9.47917 5.09583 9.2875 5.2875C9.09583 5.47917 9 5.71667 9 6C9 6.28333 9.09583 6.52083 9.2875 6.7125C9.47917 6.90417 9.71667 7 10 7ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"
                fill="#25B003"
              />
            </svg>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Объект инспекции',
      dataIndex: 'inspectionObject',
      key: 'inspectionObject',
      width: 350,
      render: ({ name, address }: { name: string; address: string }) => (
        <div className="flex flex-col gap-1">
          <p className="typography-h5">Премьер Холл Синема:</p>
          <p className="typography-h6">{address}</p>
          <p className="typography-small font-medium text-[#797979]">{name}</p>
        </div>
      ),
    },
    {
      title: 'Дата создания',
      dataIndex: 'creationDate',
      key: 'creationDate',
      width: 200,
      render: (text: string) => <span className="font-inter font-semibold">{text}</span>,
    },
    {
      title: 'Статус инспекции',
      dataIndex: 'status',
      key: 'status',
      width: 160,
      render: (status: { title: string; statusKey: string }) => <StatusComponent {...status} />,
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      width: 70,
      render: (_: any, record: { key: any }) => (
        <Button type="text" className="flex items-center justify-center !p-1" onClick={() => console.log(record.key)}>
          <img src={DeleteIcon} alt="Delete icon" className="h-[18px] w-[18px]" />
        </Button>
      ),
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      width: 70,
      render: (_: any, record: { key: any }) => (
        <Button type="text" className="flex items-center justify-center !p-1" onClick={() => console.log(record.key)}>
          <img src={EditIcon} alt="Delete icon" className="h-[18px] w-[18px]" />
        </Button>
      ),
    },
  ]

  const ExpandedRowComponent = () => {
    return <div className="">sadj</div>
  }

  return (
    <div className="">
      <ContentTitle
        title={t('inspectionApplications')}
        onClick={handleCreateNewApplication}
        btnTitle={t('createNewApplication')}
      />
      <div className="flex gap-1 rounded-2xl bg-white p-8">
        {statusesBtnData.map((item, index) => (
          <CostumeButton
            key={index}
            $countBg={item.countBg}
            onClick={() => setActiveStatus(item.statusKey)}
            $active={activeStatus === item.statusKey}
          >
            <div className="flex items-center">
              <p>{item.title}</p>
              <div className="box">
                <p>{item.count}</p>
              </div>
            </div>
          </CostumeButton>
        ))}
      </div>
      <CustomTable
        dataSource={data.map((item, index) => ({ ...item, key: index.toString() }))}
        columns={getColumns}
        expandedRowComponent={<ExpandedRowComponent />}
        bordered={false}
        page={0}
        setPageSize={() => {}}
        setPage={() => {}}
        pageSize={10}
        totalPages={100}
        scroll={{ x: 'max-content' }}
        loading={false}
        defaultExpandAllRows={true}
        rowExpandable={(record) => !!record.statusKey}
      />
    </div>
  )
}

export default GXPApplications

const CostumeButton = styled(Button)<{ $active?: boolean; $countBg?: string }>`
  background: ${({ $active }) =>
    $active ? 'linear-gradient(90deg, #4d83fb 0%, #1549bd 100%)' : 'transparent'} !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  color: ${({ $active }) => ($active ? 'white' : '#000')} !important;
  padding: 10px 20px !important;
  height: fit-content !important;
  transition: all 0.3s;

  &:hover {
    border: 1px solid #4d83fb !important;
  }
  p {
    font-weight: 500;
  }

  .box {
    background: ${({ $countBg }) => $countBg || '#F97316'};
    border-radius: 50px;
    padding: 3px 12px;
    margin-left: 10px;
    p {
      color: white;
      font-weight: bold;
    }
  }
`
