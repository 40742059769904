import { CustomBreadcrumb } from '@/components/ui/breadcrumb'
import { CustomTable } from '@/components/ui/table'
import { useNavigate, useParams } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'
// import Descriptions from '@/components/ui/Descriptions'
import TableExpandable from '@/components/ui/tableExpandable'
import { useState } from 'react'
import { useSearchDrug } from '@/hooks/useCreateDrug'
import { MNNTableDataType, SearchDrugReqType } from '@/types'
import { useAppDispatch, useAppSelector } from '@/redux/store'
import { getSelectDrug } from '@/redux/selectors/drugs'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { TableProps } from 'antd'

const initialBody = {
  page: 0,
  size: 10,
  query: '',
}

function DrugItemsScreen() {
  const navigate = useNavigate()
  // const { t } = useTranslation()
  const { itemId } = useParams()
  const dispatch = useAppDispatch()

  const selectDrug = useAppSelector(getSelectDrug)

  const [body, setBody] = useState<SearchDrugReqType>({
    ...initialBody,
    internationalNonProprietaryNameCode: itemId,
  })

  const { data, isLoading, isFetching, pagination } = useSearchDrug({
    body,
    initialBody,
    dispatch,
  })

  const onChangePageSize = (size: number) => {
    setBody(() => ({ ...initialBody, size: size }))
  }

  const onChangePage = (page: number) => {
    setBody((prev) => ({ ...prev, page: page - 1 }))
  }

  const onHandleProductDetails = (rowId: string) => {
    navigate(`/drugs/${itemId}/${rowId}`)
  }

  const columns = [
    {
      title: 'Торговое наименование',
      dataIndex: 'tradeNameRu',
      key: 'tradeNameRu',
      render: (text: string) => <p className="typography-h6 text-primary first-letter:uppercase">{text}</p>,
    },
    {
      title: 'Держатель РУ',
      dataIndex: 'holder',
      key: 'holder',
    },
    {
      title: 'Производитель',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      width: 280,
      render: (item: any) => (
        <div>
          <p className="typography-body font-semibold">{item.nameRu}</p>
          <p className="typography-small">{item.address.country.nameRu}</p>
        </div>
      ),
    },
    {
      title: 'Количество ЕИ ЛП в потребительской упаковке',
      dataIndex: 'dosage',
      key: 'dosage unitMeasureDosage',
      width: 230,
      render: (dosage: any, item: any) => {
        console.log({ item })
        return (
          <div>
            <p className="typography-body font-semibold">
              {dosage} {item.unitMeasureDosage.designation}
            </p>
            <p className="typography-small">действующего вещества</p>
          </div>
        )
      },
    },
    {
      title: 'Описание упаковки',
      dataIndex: 'primaryPackaging',
      key: 'primaryPackaging',
      render: (item: any, item2: any) => (
        <div className="flex flex-col gap-6">
          <div>
            <p className="typography-body font-semibold first-letter:uppercase">{item.nameRu}</p>
            <p className="typography-small">Первичная упаковка</p>
          </div>
          <div>
            <p className="typography-body font-semibold first-letter:uppercase">{item2.secondaryPackaging.nameRu}</p>
            <p className="typography-small">Потребительская упаковка</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Предельно отпускная цена',
      dataIndex: 'price',
      key: 'price',
    },
  ]

  const getColumns: TableProps<MNNTableDataType>['columns'] = [
    {
      title: 'МНН / Групировочное (химическое) наименование',
      dataIndex: 'innNameRu',
      key: 'innNameRu',
      width: 280,
      render: (text: string) => <p className="py-5 font-inter font-semibold capitalize text-primary">{text}</p>,
    },
    {
      title: (
        <div className="flex flex-col items-center justify-center gap-2 text-left">
          <UpOutlined />
          <p>Лекарственная форма</p>
          <DownOutlined />
        </div>
      ),
      dataIndex: 'drugForms',
      key: 'drugForms',
      width: 300,
      render: (texts: string[]) => (
        <div className="font-inter text-[16px] font-semibold">
          {texts.map((text, index) => (
            <p key={index} className="capitalize">
              {text}
            </p>
          ))}
        </div>
      ),
    },
    {
      title: 'Дозировка',
      dataIndex: 'dosages',
      key: 'dosages',
      width: 200,
      render: (text: string[]) => (
        <p className="font-inter font-semibold">
          {text.map((item, index) => (
            <span key={index} className="block">
              {item}
            </span>
          ))}
        </p>
      ),
    },
    {
      title: 'Единица измерения лекарственных препаратов',
      dataIndex: 'unitMeasureDosages',
      key: 'unitMeasureDosages',
      width: 280,
      render: (texts: string[]) => (
        <span className="font-inter font-semibold">
          {texts.map((item, index) => (
            <span key={index} className="block">
              {item}
            </span>
          ))}
        </span>
      ),
    },
    {
      title: 'Товарная позиция',
      dataIndex: 'tradeNamesRu',
      key: 'tradeNamesRu',
      render: (texts: string[]) => (
        <ul className="list-inside list-disc font-inter font-semibold">
          {texts.map((item, index) => (
            <li className="capitalize" key={index}>
              {item}
            </li>
          ))}
        </ul>
      ),
    },
  ]

  return (
    <div className="px-60 pb-20">
      <CustomBreadcrumb
        pages={[
          {
            title: 'Сервисы',
            href: '/',
          },
          {
            title: 'Лекарственные средства',
            href: '/service/drugs',
          },
        ]}
      />
      <CustomTable
        dataSource={[selectDrug]}
        columns={getColumns}
        headerTitle="Узлы СМНН"
        bordered={false}
        totalPages={0}
        rowExpandable={() => false}
        page={0}
        pageSize={0}
        setPageSize={() => {}}
        setPage={() => {}}
      />
      {/* <div className="grid grid-cols-2 gap-20 p-8 pt-0 bg-beckground">
        <Descriptions
          details={dataDescription.slice(0, 2)}
          rowClassName="!flex-nowrap"
          labelClassName="typography-body !text-black !font-medium"
          valueClassName="!typography-body !text-black !font-normal"
        />
        <Descriptions
          details={dataDescription.slice(2)}
          labelClassName="!min-w-64 typography-body !text-black !font-medium"
          valueClassName="!typography-body !text-black !font-normal"
        />
      </div> */}
      <TableExpandable
        headerTitle="Товарные позиции (КЛП)"
        dataSource={data.map((item, index) => ({ ...item, key: index.toString() }))}
        columns={columns}
        page={body.page}
        pageSize={body.size}
        setPageSize={onChangePageSize}
        setPage={onChangePage}
        totalPages={pagination.totalItems}
        onRow={(record) => ({
          onClick: () => onHandleProductDetails(record.key),
        })}
        loading={isLoading || isFetching}
      />
    </div>
  )
}

export default DrugItemsScreen
